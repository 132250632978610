export type PreloadItem = {
  type: "image" | "audio" | "video" | "other" | "import";
  src: string;
};

function loadItem(item: {
  type: "image" | "audio" | "video" | "other" | "import";
  src: string;
}): Promise<unknown> {
  switch (item.type) {
    case "image": {
      const img = new Image();
      img.src = item.src;
      return new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
      });
    }

    case "audio": {
      const audio = new Audio();
      audio.src = item.src;
      return new Promise((resolve, reject) => {
        audio.oncanplaythrough = resolve;
        audio.onerror = reject;
        audio.onabort = reject;
        audio.load(); // iOS needs this!
      });
    }

    case "video": {
      const video = document.createElement("video");
      video.src = item.src;
      return new Promise((resolve, reject) => {
        video.onloadedmetadata = resolve;
        video.onerror = reject;
      });
    }

    case "import": {
      return import(/* @vite-ignore */ item.src);
    }

    case "other":
    default: {
      // Unsupported type. Just fetch it and cross fingers
      // that the browser will cache it.
      return fetch(item.src);
    }
  }
}

export async function createAssetPreloader(
  items: PreloadItem[],
  onProgress?: (progress: number, item: PreloadItem) => void,
  onError?: (error: Error, item: PreloadItem) => void
) {
  let i = 0;
  // Load sequentially to avoid overwhelming the browser
  for (const item of items) {
    await loadItem(item)
      .then(() => onProgress?.(i / items.length, item))
      .catch((error) => onError?.(error, item));

    i++;
  }
}
