import { useEffect, useState } from "react";

import { FaPlay } from "react-icons/fa";

interface Props {
  title?: string;
  fg?: string;
  bg?: string;
  onClick?: () => void;
}

export function ComposedImage({ title, fg, bg, onClick }: Props) {
  const [fgLoaded, setFgLoaded] = useState(false);
  const [bgLoaded, setBgLoaded] = useState(false);
  const [retry, setRetry] = useState(0);

  useEffect(() => {
    if (fg) {
      setFgLoaded(false);
      const img = new Image();
      img.onload = () => setFgLoaded(true);
      img.onerror = () => setTimeout(() => setRetry((r) => r + 1), 1000);
      img.src = fg;
    } else {
      setFgLoaded(true);
    }
  }, [fg, retry]);

  useEffect(() => {
    if (bg) {
      setBgLoaded(false);
      const img = new Image();
      img.onload = () => setBgLoaded(true);
      img.onerror = () => setTimeout(() => setRetry((r) => r + 1), 1000);
      img.src = bg;
    } else {
      setBgLoaded(true);
    }
  }, [bg, retry]);

  const classes = ["ufo-composed-image"];
  if (bgLoaded && fgLoaded) classes.push("loaded");

  return (
    <div
      style={{ backgroundImage: bg && bgLoaded ? `url(${bg})` : "gray" }}
      className={classes.join(" ")}
      onClick={onClick}
    >
      {fgLoaded && fgLoaded
        ? fg && <img src={fg} className="ufo-foreground-image" alt={title} />
        : null}

      <div className="ufo-play-button">
        <FaPlay />
      </div>
    </div>
  );
}
