import { useCallback, useEffect, useState } from "react";
import {
  MdFullscreen,
  MdFullscreenExit,
  MdVolumeMute,
  MdVolumeUp,
} from "react-icons/md";

interface PlayerControlsProps {
  muted?: boolean;
  fullscreen?: boolean;
  onToggleMute?: () => void;
  onToggleFullScreen?: () => void;
}

export function PlayerControls(props: PlayerControlsProps) {
  const { muted, fullscreen, onToggleMute, onToggleFullScreen } = props;
  const [hover, setHover] = useState(false);

  const handleShow = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setHover(true);
  }, []);

  const handleHide = useCallback(() => {
    setHover(false);
  }, []);

  useEffect(() => {
    if (hover) {
      document.addEventListener("click", handleHide);
    }

    return () => {
      document.removeEventListener("click", handleHide);
    };
  }, [handleHide, hover]);

  return (
    <div className="ufo-player-controls" onClick={handleShow}>
      <div className="inner">
        {onToggleMute ? (
          <a onClick={onToggleMute}>
            {muted ? <MdVolumeMute /> : <MdVolumeUp />}
          </a>
        ) : null}
        {onToggleFullScreen ? (
          <a onClick={onToggleFullScreen}>
            {fullscreen ? <MdFullscreenExit /> : <MdFullscreen />}
          </a>
        ) : null}
      </div>
    </div>
  );
}
