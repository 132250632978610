import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import DocumentMeta from "react-document-meta";
import { useFirebase } from "@shared/firebase";

import GameEngine, {
  DEFAULT_THEME,
  type GameLogEntry,
  type GameStateInterface,
  type GameDataInterface,
  type GameEventType,
} from "@shared/game-engine";
import { type GameDefinitionDto, PlayerApiClient } from "@shared/api-client";
import {
  GamePlayerOffline,
  RequireInteractionBoundary,
  FullScreenBoundary,
  type RenderingContext,
  PreloadItem,
  createAssetPreloader,
} from "@shared/game-player";
import { deobfuscate, obfuscate } from "@shared/utils/obfuscator";
import { gluePath } from "@shared/utils/paths";

import { Loading } from "./components/Loading";
import { UfoPild } from "./components/UfoPild";
import { ComposedImage } from "./components/ComposedImage";
import { createLogger } from "@shared/utils/logging/logger";
import { useTagManager } from "./hooks/useTagManager";
import { PlayerControls } from "./components/PlayerControls";

import { FaPlay } from "react-icons/fa";
import { HiOutlineArrowsExpand } from "react-icons/hi";
import { CiMobile2 } from "react-icons/ci";

const logger = createLogger("GameEngine", {
  canLog: () => window.__debug__,
});

// Irrelevant events for analytics
const SKIP_EVENTS: (keyof GameEventType)[] = [
  "gameStateUpdated",
  "gameLogUpdated",
  "gameActionEnqueued",
];

const apiClient = new PlayerApiClient(import.meta.env.VITE_BACKEND_BASEURL);
const isIframe = window.location.search.includes("iframe");

function Player() {
  const { logEvent, userUuid } = useFirebase();
  const tm = useTagManager();
  const [interactive, setInteractive] = useState(!isIframe);
  const [loading, setLoading] = useState(true);
  const [loadProgress, setLoadProgress] = useState(0);
  const [uuid, setUuid] = useState<string>();
  const [error, setError] = useState<Error | null>();
  const [game, setGame] = useState<GameDefinitionDto | null>();
  const [gameData, setGameData] = useState<GameDataInterface | null>();
  const [muted, setMuted] = useState(false);

  // For notifying the backend
  const [stateUuid, setStateUuid] = useState<string>();
  const [pendingGameState, setPendingGameState] = useState<{
    state: GameStateInterface;
    log: GameLogEntry[];
  } | null>(null);

  const viewportRef = useRef<HTMLDivElement>(null);
  const [ready, setReady] = useState(false);
  const [renderingContext, setRenderingContext] =
    useState<Partial<RenderingContext>>();

  // #1. Parse URL
  useEffect(() => {
    const uuid = window.location.pathname.split("/")[1];
    setUuid(uuid);

    tm("page_view", {
      page_location: location.href,
      page_title: document.title,
      page_path: location.pathname,
      game_uuid: uuid,
      iframe: isIframe,
      iframe_host_location: window.location.href,
      referrer: document.referrer,
    });
  }, [tm]);

  // #2. Game load
  useEffect(() => {
    if (!uuid) {
      return;
    }

    setLoading(true);

    apiClient
      .get({ path: "/g/:uuid", params: { uuid } })
      .then(setGame)
      .catch(setError);
  }, [uuid]);

  // #3. Load game data
  useEffect(() => {
    if (!game || !interactive) {
      return;
    }

    tm("game_load", {
      game_uuid: uuid,
      iframe: isIframe,
    });

    apiClient
      .get({
        path: "/g/:uuid/data",
        params: { uuid: game.uuid },
      })
      .then(async (gameData) => {
        const parsedGameData = JSON.parse(
          deobfuscate(gameData.d, game.uuid)
        ) as GameDataInterface;

        setGameData(parsedGameData);
      });
  }, [game, interactive, tm, uuid]);

  // #4. Preload assets
  useEffect(() => {
    if (!gameData || !game) return;

    // Load game assets and plugins
    (async () => {
      const resources = await apiClient.get({
        path: "/g/:uuid/prefetch",
        params: { uuid: game.uuid },
      });

      const items: PreloadItem[] = [];

      // Translate assets to preload items
      resources.assets.forEach((asset) => {
        // URL format: type/assetName
        const type = (asset.split(/\//g)[0] as PreloadItem["type"]) || "other";

        items.push({
          type,
          src: gluePath(game?.assetBaseUrl, asset),
        });
      });

      // Translate libraries to preload items
      resources.library.forEach((asset) => {
        // URL format: collectionId/type/assetName
        const type = (asset.split(/\//g)[1] as PreloadItem["type"]) || "other";

        items.push({
          type,
          src: gluePath(import.meta.env.VITE_LIBRARY_BASE_URL, asset),
        });
      });

      // Translate components to preload items
      resources.components.forEach((componentName) => {
        const isBuiltin =
          componentName.startsWith("<") && componentName.endsWith(">");

        items.push({
          type: "import",
          src: gluePath(
            isBuiltin
              ? import.meta.env.VITE_PLUGIN_BUILTIN_BASE_URL
              : import.meta.env.VITE_PLUGIN_BASE_URL,
            "game-component",
            componentName.replace(/^<|>$/g, ""),
            isBuiltin ? import.meta.env.VITE_PLUGIN_INDEX_FILE : "index.js"
          ),
        });
      });

      await createAssetPreloader(items, (progress) => {
        setLoadProgress(progress);
      });

      setLoading(false);
    })();
  }, [game, game?.assetBaseUrl, game?.uuid, gameData]);

  // #5. Apply theme
  useEffect(() => {
    if (!gameData) return;

    // Apply theme
    // TODO: Use a component
    document.body.style.backgroundColor =
      gameData.theme?.background || DEFAULT_THEME.background || "#FFFFFF";
    document.body.style.setProperty(
      "--foreground-color",
      gameData?.theme?.text || DEFAULT_THEME.text || "#000000"
    );

    document.body.style.color =
      gameData?.theme?.text || DEFAULT_THEME.text || "#000000";
    document.body.style.setProperty(
      "--background-color",
      gameData?.theme?.background || DEFAULT_THEME.background || "#000000"
    );
  }, [gameData]);

  /* Handle window resize */
  useEffect(() => {
    if (!gameData?.screen || !viewportRef.current) return;

    const updateScale = () => {
      if (!gameData?.screen || !viewportRef.current) return;

      const bounds = viewportRef.current.getBoundingClientRect();
      const scale = Math.min(
        bounds.height / gameData.screen.height,
        bounds.width / gameData.screen.width
      );

      setRenderingContext({
        viewportWidth: bounds.width,
        viewportHeight: bounds.height,
        canvasScale: scale,
        canvasX: bounds.width / 2 - (gameData.screen.width * scale) / 2,
        canvasY: bounds.height / 2 - (gameData.screen.height * scale) / 2,
      });
    };

    updateScale();

    // Observe the viewport for changes
    const observer = new ResizeObserver(updateScale);
    observer.observe(viewportRef.current);

    return () => {
      observer.disconnect();
    };
  }, [gameData?.screen, ready, loading]);

  // #6. Handle events debounced
  useEffect(() => {
    if (!pendingGameState || !uuid || !stateUuid) return;

    // Extra context for the backend
    // TODO: Move to a common place
    const playerContext = {
      iframe: isIframe,
      muted,
      fullscreen: document.fullscreenElement !== null,
      mobile: window.matchMedia("(max-width: 768px)").matches,
      portrait: window.matchMedia("(orientation: portrait)").matches,
      landscape: window.matchMedia("(orientation: landscape)").matches,
      touch: "ontouchstart" in window || navigator.maxTouchPoints > 0,
      width: window.innerWidth,
      height: window.innerHeight,
      scale: window.devicePixelRatio,
      language: navigator.language,
      userAgent: navigator.userAgent,
      cookieEnabled: navigator.cookieEnabled,
    };

    const { state, log } = pendingGameState;

    const notifyBackend = () => {
      const stateDataEncrypted = obfuscate(
        JSON.stringify({ ...state, userUuid, playerContext }),
        stateUuid
      );
      const logEncrypted = obfuscate(JSON.stringify(log), stateUuid);
      apiClient.post(
        {
          path: "/g/:uuid/s/:stateUuid",
          params: { uuid, stateUuid },
        },
        { d: stateDataEncrypted, l: logEncrypted }
      );

      setPendingGameState(null);
    };

    const timeout = setTimeout(notifyBackend, 1000);
    return () => clearTimeout(timeout);
  }, [muted, pendingGameState, stateUuid, userUuid, uuid]);

  // #7. Ping backend
  useEffect(() => {
    if (!uuid || !stateUuid) return;

    if (document.visibilityState !== "visible") {
      // Don't ping the backend if the page is not visible
      return;
    }

    const pingBackend = () => {
      apiClient.post(
        {
          path: "/g/:uuid/s/:stateUuid",
          params: { uuid, stateUuid },
        },
        null // No body
      );
    };

    const timeout = setInterval(pingBackend, 15000);
    return () => clearInterval(timeout);
  }, [stateUuid, uuid]);

  const onEventHandler = useCallback(
    (event: string, data: Record<string, unknown>, engine: GameEngine) => {
      // Store the state for the backend
      if (event === "gameStateUpdated" && uuid && engine) {
        const state = engine.getState();
        const log = engine.getLog();
        setPendingGameState({ state, log });
        setStateUuid(state.uuid);
      }

      // Log events
      if (!SKIP_EVENTS.includes(event as keyof GameEventType)) {
        const filteredData = Object.keys(data).reduce<Record<string, unknown>>(
          (acc, key) => {
            // Filter out non-serializable data
            if (["string", "number", "boolean"].includes(typeof data[key])) {
              return { ...acc, [key]: data[key] };
            }
            return acc;
          },
          {}
        );

        logEvent?.(event, {
          game_uuid: uuid,
          iframe: isIframe,
          ...filteredData,
        });

        tm("game_load", {
          game_uuid: uuid,
          iframe: isIframe,
          data: filteredData,
        });
      }
    },
    [logEvent, tm, uuid]
  );

  const playerInnerStyles = useMemo(
    () => ({
      width: gameData?.screen?.width,
      height: gameData?.screen?.height,
      transform: `translate(-50%, -50%) scale(${renderingContext?.canvasScale ?? 1})`,
    }),
    [
      gameData?.screen?.width,
      gameData?.screen?.height,
      renderingContext?.canvasScale,
    ]
  );

  const playerSettings = useMemo(
    () => ({
      assetBaseUrl: game?.assetBaseUrl || "/assets",
      libraryBaseUrl: import.meta.env.VITE_LIBRARY_BASE_URL || "/library",
      pluginBaseUrl: import.meta.env.VITE_PLUGIN_BASE_URL,
      builtinPluginBaseUrl: import.meta.env.VITE_PLUGIN_BUILTIN_BASE_URL,
      pluginIndexFile: import.meta.env.VITE_PLUGIN_INDEX_FILE,
    }),
    [game]
  );

  const docMeta = useMemo(
    () => ({
      "application-name": `UFOLab ${game?.name ? `| ${game.name}` : ""}`,
    }),
    [game]
  );

  if (error) {
    throw error;
  }

  return (
    <DocumentMeta
      title={docMeta["application-name"]}
      description={game?.description}
      meta={docMeta}
    >
      {!interactive && (
        <ComposedImage
          title={game?.name}
          fg={game?.fgImage}
          bg={game?.bgImage}
          onClick={() => setInteractive(true)}
        />
      )}
      {interactive && <Loading loaded={!loading} progress={loadProgress} />}
      {gameData && !loading && (
        <FullScreenBoundary
          requireFullScreen={gameData.screen?.fullScreen}
          requireOrientation={gameData.screen?.orientation}
          fullScreenWarning={(doFullScreen) => (
            <div className="ufo-full-screen-warning" onClick={doFullScreen}>
              <div className="ufo-play-button">
                <HiOutlineArrowsExpand />
              </div>
            </div>
          )}
          orientationWarning={
            <div className="ufo-full-screen-warning">
              <div
                className={`ufo-rotate-warning ${gameData.screen?.orientation === "portrait" ? "reversed" : ""}`}
              >
                <CiMobile2 />
              </div>
            </div>
          }
        >
          <div className="ufo-game-player" draggable={false} ref={viewportRef}>
            <div className="ufo-game-player-inner" style={playerInnerStyles}>
              <RequireInteractionBoundary
                enabled={false} // Disable the warning for now
                warning={(play) => (
                  <div
                    className="ufo-full-screen-warning"
                    onClick={() => {
                      setReady(true);
                      play();
                    }}
                  >
                    <div className="ufo-play-button">
                      <FaPlay />
                    </div>
                  </div>
                )}
              >
                <GamePlayerOffline
                  gameData={gameData}
                  settings={playerSettings}
                  onEvent={onEventHandler}
                  renderingContext={renderingContext}
                  logger={logger}
                  globalVolume={muted ? 0 : 1}
                />
              </RequireInteractionBoundary>
            </div>
          </div>
          <UfoPild />
          <PlayerControls
            muted={muted}
            onToggleMute={() => setMuted(!muted)}
            fullscreen={document.fullscreenElement !== null}
            onToggleFullScreen={() => {
              document.fullscreenElement
                ? document.exitFullscreen()
                : document
                    .getElementsByClassName("fullscreen")[0]
                    ?.requestFullscreen();
            }}
          />
        </FullScreenBoundary>
      )}
    </DocumentMeta>
  );
}

export default Player;
