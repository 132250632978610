import React, {
  createContext,
  useMemo,
  useCallback,
  useEffect,
  useState,
} from "react";

import {
  type FirebaseApp,
  type FirebaseOptions,
  initializeApp,
} from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { signInAnonymously, getAuth } from "firebase/auth";

// Define the shape of your context value
export interface FirebaseContextValue {
  logEvent?: (event: string, data: Record<string, unknown>) => void;
  setContext?: (context: Record<string, unknown>) => void;
  userUuid?: string;
  setUserUuid?: (userUuid: string) => void;
}

// Create the context
export const FirebaseContext = createContext<FirebaseContextValue | undefined>(
  undefined
);

interface Props {
  config: FirebaseOptions;
  children: React.ReactNode;
}

type FirebaseContext = Record<string, unknown>;

// Create a provider component
export const FirebaseProvider = ({ children, config }: Props) => {
  const [app, setApp] = useState<FirebaseApp>();
  const [userUuid, setUserUuid] = React.useState<string>();
  const [context, setContext] = React.useState<FirebaseContext>({});
  const analytics = app && getAnalytics(app);

  const signIn = useCallback(async () => {
    const auth = getAuth(app);
    const authResponse = await signInAnonymously(auth);
    setUserUuid(authResponse.user.uid);
  }, [app]);

  const value: FirebaseContextValue = useMemo(
    () => ({
      logEvent: (event: string, data: Record<string, unknown>) => {
        const payload = {
          ...context,
          user_uuid: userUuid,
          ...data,
        };

        if (analytics) {
          logEvent(analytics, event, payload);
        } else {
          console.debug("[Firebase] logEvent", event, payload);
        }
      },
      setContext,
      context,
      signIn,
      userUuid,
    }),
    [analytics, context, signIn, userUuid]
  );

  useEffect(() => {
    if (config.apiKey) {
      setApp(initializeApp(config));
    }
  }, [config]);

  // Automatically sign in when the app is initialized
  useEffect(() => {
    if (app) {
      signIn();
    }
  }, [app, signIn]);

  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
};
