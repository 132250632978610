// import React from "react";
import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import TagManager from "react-gtm-module";

import PlayerPage from "./Player.tsx";
import ErrorPage from "./Error.tsx";

import "@shared/branding/fonts.css";

import "./index.css";

import { FirebaseProvider } from "@shared/firebase";
import { createAnimationsStyleTag } from "@shared/game-player/utils/animations.ts";

// Setup TagManager
TagManager.initialize({
  gtmId: import.meta.env.VITE_GTM_ID,
  auth: import.meta.env.VITE_GTM_AUTH,
  preview: import.meta.env.VITE_GTM_PREVIEW,
  dataLayer: {
    environment: import.meta.env.MODE,
  },
});

createAnimationsStyleTag();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <ErrorBoundary FallbackComponent={ErrorPage}>
    <FirebaseProvider
      // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
      config={{
        apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
        authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
        databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
        projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
        storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
        appId: import.meta.env.VITE_FIREBASE_APP_ID,
        measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
      }}
    >
      <PlayerPage />
    </FirebaseProvider>
  </ErrorBoundary>
);
