import RocketGif from "@shared/branding/theufolab/rocket.gif";

interface Props {
  loaded?: boolean;
  progress?: number; // 0-1
}

export function Loading(props: Props) {
  const { loaded, progress = 0 } = props;

  return (
    <div className={`ufo-page-loading ${loaded ? "out" : ""}`}>
      <div className="rocket">
        <img src={RocketGif} alt="Loading..." />
      </div>
      <div className="progress">
        <div
          className="progress-bar"
          style={{ height: `${(progress ?? 0) * 100}%` }}
        />
      </div>
    </div>
  );
}
